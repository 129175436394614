.carousel-wrapper {
  overflow: hidden;
  width: 100%;

  .carousel-items {
    margin: 0 auto;
    display: flex;
    .carousel-item {
      flex: 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .img-wrapper {
        width: 80px;
        height: 80px;
        position: relative;
      }
    }
  }
}
